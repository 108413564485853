/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "tailwind.macro"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AppWrapper from "../views/AppWrapper"
import Content, { Inner } from "../elements/Content"
import Hero from "../views/Hero"
import CircleIcon from "../elements/CircleIcon"
import {
  BigTitleLight,
  H2,
  H4Style,
  P,
  PLight,
  Highlight,
} from "../elements/Text"

const SectionWrapper = styled(Inner)`
  ${tw`lg:flex lg:flex-wrap justify-center`}
`

const SectionContainer = styled.div`
  ${tw`lg:w-1/2 xl:w-1/3 mb-8 text-center bg-white`}
`

const SectionHeader = styled(H2)`
  ${tw`uppercase mb-0 text-center`};
`

const Separator = styled.span`
  ${tw`w-1/4 bg-pink h-2 mx-auto block`};
`

const SectionContents = styled.div`
  ${tw`text-center`};
`

const Name = styled.span`
  ${tw`block mt-4`};
`

const Title = styled(P)`
  ${H4Style};
  ${tw`mt-2 mb-0`};
`

const Avatar = styled(Img)`
  ${tw`rounded-full w-32 h-32 shadow-lg m-auto`};
`

const SectionSocial = styled.div`
  ${tw`flex items-center justify-center text-grey-dark my-2`};
  min-height: 54px;
`

const LinkItem = styled.a`
  ${tw`inline-block m-1`};
  ${tw`text-grey-dark`};
`

const Section = ({ children, ...props }) => {
  const { name, title, image } = props

  return (
    <SectionContainer>
      <SectionHeader>
        <Avatar alt={name} fluid={image} />

        <Name>
          <Highlight>{name}</Highlight>
        </Name>
      </SectionHeader>
      <Title>{title}</Title>

      <SectionContents>{children}</SectionContents>
      <Separator />
    </SectionContainer>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.any.isRequired,
}

const MembersList = data => {
  const { images, datafromjson } = data.data

  return (
    <>
      {datafromjson.edges.map(item => {
        const imageIndex = images.edges.findIndex(
          x =>
            x.node.name ===
            item.node.image
              .split(".")
              .slice(0, -1)
              .join(".")
        )
        return (
          <Section
            key={imageIndex}
            name={item.node.name}
            title={item.node.title}
            image={images.edges[imageIndex].node.childImageSharp.fluid}
          >
            <SectionSocial>
              {item.node.bio ? (
                item.node.bio
              ) : (
                <>
                  {item.node.linkedin && (
                    <LinkItem
                      href={`https://linkedin.com/in/${item.node.linkedin}`}
                      target="_blank"
                      alt="LinkedIn"
                    >
                      <CircleIcon icon="linkedin" size={42} />
                    </LinkItem>
                  )}
                  {item.node.github && (
                    <LinkItem
                      href={`https://github.com/${item.node.github}`}
                      target="_blank"
                      alt="GitHub"
                    >
                      <CircleIcon icon="github" size={42} />
                    </LinkItem>
                  )}
                  {item.node.twitter && (
                    <LinkItem
                      href={`https://twitter.com/${item.node.twitter}`}
                      target="_blank"
                      alt="Twitter"
                    >
                      <CircleIcon icon="twitter" size={42} />
                    </LinkItem>
                  )}
                </>
              )}
            </SectionSocial>
          </Section>
        )
      })}
    </>
  )
}

const Team = () => (
  <StaticQuery
    query={graphql`
      query AllTeamMemberImagesJSON {
        images: allFile(filter: { absolutePath: { regex: "/avatars/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        datafromjson: allTeamMembersJson {
          edges {
            node {
              name
              title
              bio
              image
              github
              twitter
              linkedin
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <SEO title="The Featurist Team" />
        <AppWrapper>
          <Hero isDark style={{ zIndex: 20 }}>
            <BigTitleLight>
              the
              <Highlight> Featurists</Highlight>
            </BigTitleLight>
            <PLight />
          </Hero>
          <Content>
            <SectionWrapper>
              <MembersList data={data} />
            </SectionWrapper>
          </Content>
        </AppWrapper>
      </Layout>
    )}
  />
)

export default Team
